@import 'src/styles/colors';

.root {
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 4px 10px 0 16px;
  font-size: 2rem;
  display: grid;
  grid-auto-flow: column;
  column-gap: 10px;
  background-color: color(background, cream);
  box-shadow: 0 0 10px -0 #00000090;
  border-top-left-radius: 8px;

  animation: slideIn 0.75s forwards;

  @keyframes slideIn {
    0% {
      transform: translateX(100%);
    }
    50% {
      padding-right: 50px;
      transform: translateX(0);
    }
    80% {
      transform: translateX(20px);
    }
    100% {
      padding-right: 10px;
      transform: translateX(0);
    }
  }

  @media screen and (max-height: 420px) {
    padding-left: 12px;
    font-size: 1.5rem;
  }

  @media screen and (max-width: 420px) {
    padding-left: 12px;
    font-size: 1.3rem;
  }

  > a {
    color: color(text, dark-primary);
    opacity: 0.75;
    transition: opacity 0.2s;

    &:hover {
      opacity: 1;
    }
  }
}
