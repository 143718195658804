@import 'src/styles/sizes';

.root {
  display: grid;
  grid-auto-flow: row;
  row-gap: clamp(20px, 5vw, 30px);
  padding: $header-margin-bottom 0 0;
  margin: auto;

  @media screen and (max-height: 420px) {
    padding: 0;
  }

  hr {
    width: 90%;
    opacity: 0.3;

    &:first-child {
      margin: -10px auto 10px;
    }
  }
}
