@import 'src/styles/backgrounds';
@import 'src/styles/colors';
@import 'src/styles/sizes';

.overlay {
  position: fixed;
  inset: 0px;
  background-color: rgba(20, 20, 20, 0.75);
  animation: fadeIn 0.1s forwards;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

.modal {
  background-color: color(background, cream);
  position: absolute;
  overflow: auto;
  border-radius: 4px;
  outline: 0;
  box-shadow: 0 0 10px -0 #00000090;

  max-width: min(90%, 1100px);
  width: 90%;
  max-height: 90%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media screen and (max-width: 666px) {
    width: 100%;
    max-height: 100%;
    border-radius: 0;
  }

  @media screen and (max-height: 420px) {
    width: 100%;
    max-height: 100%;
    border-radius: 0;
    padding-top: $header-margin-bottom;
  }

  button {
    position: absolute;
    top: 12px;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    border: none;
    font-size: 1.25rem;
    opacity: 0.75;
    transition: opacity 0.3s, transform 0.1s;

    @media screen and (max-width: 666px) {
      top: 8px;
      right: 6px;
    }

    @media screen and (max-height: 420px) {
      top: 20px;
    }

    svg path {
      color: color(text, dark-primary);
    }

    &:hover {
      opacity: 1;
      cursor: pointer;
    }

    &:active {
      transform: scale(0.9);
    }
  }

  p,
  h2,
  h3,
  h4,
  li {
    color: color(text, dark-primary) !important;
  }
}
