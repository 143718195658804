@import 'src/styles/backgrounds';

html {
  @media screen and (max-height: 420px) {
    width: 100% !important;
  }

  @media screen and (max-width: 420px) {
    width: 100% !important;
  }
}

body {
  height: 100%;
  background: url('./images/#{$main-background-name}.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: 50%;

  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', sans-serif;

  * {
    user-select: none;
  }
}

p,
span,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
li {
  margin: 0;
}
