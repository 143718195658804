@import 'src/styles/colors';

.links {
  list-style: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 15px;
  row-gap: 15px;

  @media screen and (max-width: 666px) {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 10px;
    row-gap: 10px;
  }

  li {
    border: solid 1px white;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    transition: background-color 0.3s;

    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }

  a {
    width: 100%;
    height: 100%;
    padding: 20px 10px;
    box-sizing: border-box;
    display: grid;
    grid-template-rows: auto 1fr;
    row-gap: 10px;
    text-align: center;
    text-decoration: none;

    color: color(text, light-primary);

    svg {
      font-size: 1.5rem;
      margin: auto;
    }
  }
}
