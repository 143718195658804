@import 'src/styles/colors';

.root,
.content {
  padding-bottom: clamp(20px, 5vw, 30px);

  li {
    line-height: 2.5rem;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
