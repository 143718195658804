@import 'src/styles/colors';

.root {
  display: grid;
  grid-auto-flow: row;
  row-gap: clamp(20px, 5vw, 30px);
  padding-bottom: clamp(20px, 5vw, 30px);

  h2 {
    margin-bottom: 0;
  }

  button {
    padding: 0;
    text-align: left;
    background: none;
    border: none;
    font-size: 1em;
    line-height: 1.7rem;
  }

  a {
    color: color(edcc-blue, default);
    text-decoration: none;
  }
}
