@import 'src/styles/colors';

.root {
  display: grid;
  grid-auto-flow: row;
  row-gap: 1rem;
  line-height: 1.7rem;
  color: color(text, light-primary);
  padding: 0 clamp(20px, 5vw, 40px);

  * {
    user-select: text;
  }

  a, u {
    color: color(text, link-light);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  h2 {
    font-weight: 500;
    margin-bottom: 1rem;
  }
}
