.root {
  display: grid;
  grid-auto-rows: 200px;
  grid-template-columns: repeat(3, 1fr);
  padding: 0 clamp(20px, 5vw, 40px) clamp(20px, 5vw, 30px);
  column-gap: 10px;
  row-gap: 10px;

  &.inline {
    padding: 10px clamp(20px, 5vw, 40px) 0;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    grid-auto-rows: 15vh;
  }

  @media screen and (max-width: 666px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 666px) {
    grid-auto-rows: 30vw;
  }

  @media screen and (max-height: 420px) {
    grid-auto-rows: 20vw;
  }

  &.inline {
    > :nth-child(n + 5) {
      display: none;
    }

    @media screen and (max-width: 666px) {
      padding-top: 5px;
      grid-auto-rows: 25vw;

      > :nth-child(n + 3) {
        display: none;
      }
    }

    @media screen and (max-height: 420px) {
      padding-top: 5px;
      grid-auto-rows: 15vw;

      > :nth-child(n + 4) {
        display: none;
      }
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
  }
}
