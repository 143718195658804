@import 'src/styles/colors';

.root {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    background-color: color(background, cream);

    @media screen and (max-width: 666px) {
        padding: 0 clamp(20px, 5vw, 40px);
        row-gap: clamp(20px, 5vw, 40px);
        grid-template-columns: 1fr;
        background: none;
    }

    div {
        display: grid;
        grid-auto-flow: row;
        padding: clamp(20px, 3vh, 60px) clamp(20px, 5vw, 40px);
        background-color: color(background, cream);
        border-radius: 10px;

        @media screen and (max-width: 666px) {
            padding: 15px 20px;
        }

        .button {
            padding: 5px 10px;
            color: color(edcc-blue, default);
            border: solid 3px color(edcc-blue, default);
            border-radius: 5px;
            text-align: center;
            text-decoration: none;
            font-weight: 500;
            transition: color 0.2s, background-color 0.2s;

            @media screen and (max-width: 666px) {
                padding: 10px 15px;
            }

            &:hover {
                cursor: pointer;
                background-color: color(edcc-blue, default);
                color: color(text, light-primary);
            }
        }

        .moreInformation {
            color: color(edcc-blue, default);
            text-decoration: none;
            margin: auto;
            margin-top: 10px;
            font-size: 0.9rem;
        }
    }

    h2, span {
        display: inline-block;
        white-space: nowrap;
    }

    img {
        margin: auto;
        width: 80%;

        @media screen and (max-width: 666px) {
            max-width: 50%;
        }
    }
}
