$main-background-name: 'background';
$content-background-name: 'background-overlay';

@mixin fixed-background {
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: 50%;
}

@mixin background($background-image-path) {
  background: url($background-image-path);
  @include fixed-background;
}

@mixin background-dark($background-image-path) {
  background: url($background-image-path);
  background: linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.88)), url($background-image-path);
  @include fixed-background;
}

@mixin background-extra-dark($background-image-path) {
  background: url($background-image-path);
  background: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.95), #000), url($background-image-path);
  @include fixed-background;
}
