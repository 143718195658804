@import 'src/styles/colors';

.root {
  background-color: color(background, cream);
  padding: clamp(20px, 3vh, 60px) clamp(20px, 5vw, 40px);

  @media screen and (max-height: 420px) {
    display: grid;
    grid-template-columns: 3fr 2fr;
    column-gap: 20px;
  }

  h3 {
    margin-bottom: 0.5rem;

    &:not(:first-child) {
      margin-top: 1rem;
    }
  }

  ul {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    row-gap: 1rem;

    li {
      white-space: nowrap;

      h4 {
        margin-bottom: 0.5rem;
      }
    }
  }

  .contact {
    display: grid;
    grid-auto-flow: row;
    column-gap: 0.75rem;
    width: min-content;
    margin-top: 0.5rem;

    a {
        display: flex;
        align-items: center;
        color: color(edcc-blue, default);
        text-decoration: none;

        :first-child {
            margin-right: 1ch;
        }
    }
  }

  .map {
      margin-top: 2rem;
      width: 100%;
      min-height: 400px;
      border: solid 5px white;
      box-sizing: border-box;

      @media screen and (max-width: 420px) {
        min-height: 300px;
      }

      @media screen and (max-height: 420px) {
        margin: 0;
        min-height: 100%;
      }
  }
}
