@import 'src/styles/colors';

.root {
  background-color: color(background, cream);
  padding: clamp(20px, 3vh, 60px) clamp(20px, 5vw, 40px);
}

.policyItems {
  margin-top: 20px;
  padding: 0;
  list-style: none;
  border: solid 1px color(background, cream-muted-dark);
  border-radius: 8px;

  li {
    padding: 10px;
    text-align: center;
    transition: background-color 0.3s;

    &:first-child {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    &:last-child {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    &:nth-child(odd) {
      background-color: color(background, cream-muted);
    }

    &:hover {
      cursor: pointer;
      background-color: color(background, cream-muted-dark);
    }
  }
}
