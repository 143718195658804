@import 'src/styles/colors';

.root {
  width: 100%;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;

  &.light {
    background-color: color(background, cream);

    button {
      color: color(text, dark-secondary);

      &:hover {
        color: color(text, dark-primary);
      }
    }
  }

  button {
    padding: 10px;
    background: none;
    border: none;
    color: color(text, light-secondary);
    transition: color 0.3s;

    &:hover {
      color: color(text, light-primary);
      cursor: pointer;
    }
  }
}
