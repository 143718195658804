@import 'src/styles/colors';
@import 'src/styles/sizes';
@import 'src/styles/backgrounds';

.root {
  @include background-dark('../../images/#{$content-background-name}.jpg');
  display: grid;
  grid-template-columns: 1fr min-content 1fr;
  row-gap: 12px;
  align-items: center;
  padding-bottom: $header-margin-bottom;
  padding: 20px 20px 15px;
  position: sticky;
  top: -1px;
  transition: border 0.3s, box-shadow 0.3s;
  max-width: min(100%, 900px);
  margin: auto;

  @media screen and (max-height: 420px) {
    padding: 10px 20px 7px;
    row-gap: 0;
  }

  @media screen and (max-width: 420px) {
    padding: 15px 20px 10px;
  }

  .logo {
    min-width: 80px;
    max-width: min(100%, 200px);

    @media screen and (max-height: 420px) {
      min-width: 70px;
    }

    @media screen and (max-width: 420px) {
      min-width: 70px;
    }
  }

  h1 {
    font-weight: 400;
    text-transform: uppercase;
    color: color(text, light-primary);
    font-size: 30px;
    transition: font-size 0.3s;
    font-family: cinzel, -apple-system, BlinkMacSystemFont, 'Segoe UI', sans-serif;

    &:first-child {
      text-align: right;
    }

    @media screen and (max-height: 420px) {
      font-size: 26px;
    }

    @media screen and (max-width: 420px) {
      font-size: 26px;
    }
  }

  &.fixed {
    border-bottom: solid 2px color(edcc-yellow, default);
    box-shadow: 0 10px 10px -5px #00000090;

    h1 {
      font-size: 24px;

      @media screen and (max-height: 420px) {
        font-size: 22px;
      }

      @media screen and (max-width: 420px) {
        font-size: 22px;
      }
    }
  }
}

.navigation {
  grid-column: 1/-1;
  margin: auto;
  width: fit-content;
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
  align-items: center;
  column-gap: 20px;
  min-width: 40%;

  a {
    padding: 2px;
    color: color(text, light-secondary);
    text-decoration: none;
    text-align: center;
    transition: color 0.3s, transform 0.3s;
    transform: scale(0.9);
    text-transform: uppercase;

    &.active {
      transform: scale(1.05);
      color: color(text, light-primary);
    }

    &:hover {
      color: color(text, light-primary);
    }
  }
}
