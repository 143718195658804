@import 'src/styles/colors';
@import 'src/styles/sizes';
@import 'src/styles/backgrounds';

.root {
  @include background-dark('./images/#{$content-background-name}.jpg');
  padding: $header-margin-top 0 0;
  max-width: min(100%, 900px);
  margin: auto;
  box-shadow: 0 0 10px color(shadow, page);
  min-height: calc(100vh - $header-margin-top);

  @media screen and (max-height: 420px) {
    padding: calc($header-margin-top / 2) 0 0;
    min-height: calc(100vh - calc($header-margin-top / 2));
  }

  @media screen and (max-width: 666px) {
    padding: calc($header-margin-top / 2) 0 0;
    min-height: calc(100vh - calc($header-margin-top / 2));
  }
}
